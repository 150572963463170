<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="row mx-0 mt-2">
            <div class="col-8">
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 185px);">
                    <router-view />
                </div>
            </div>
            <div class="col px-0 bg-white br-t-8">
                <div class="row mx-0 border-bottom" style="height:44px;">
                    <div
                    class="h-100 col d-middle-center cr-pointer py-2"
                    :class="tab=='detalle'? 'border-b-black text-general':''"
                    @click="tab='detalle'"
                    >
                        Pedido {{ id_pedido }}
                    </div>
                    <div
                    class="h-100 col d-middle-center cr-pointer py-2"
                    :class="tab=='productos'? 'border-b-black text-general':''"
                    @click="tab='productos'"
                    >
                        Productos
                    </div>
                </div>
                <div class="mx-0 ml-2 ">
                    <productos v-show="tab === 'productos'" @credito="openCredito" />
                    <detalle v-show="tab === 'detalle'" ref="viewDetalle" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    components:{
        productos: () => import('../components/productos'),
        detalle: () => import('../components/detalle')
    },
    data(){
        return {
            tab:'detalle',
            rutas: [
                {
                    titulo: 'Por estado',
                    ruta: 'admin.almacen.productos.estado',
                    can:'tab2_abastecimiento_productos_estado'
                },
                {
                    titulo: 'Desconocidos',
                    ruta: 'admin.almacen.productos.desconocidos',
                    can:'tab2_abastecimiento_productos_desconocidos'
                },
                {
                    titulo: 'Por Pedidos',
                    ruta: 'admin.almacen.productos.pedidos',
                    can:'tab2_abastecimiento_productos_pedido'
                }
            ]
        }
    },
    computed:{ 
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
        })
    },
    methods: {
        openCredito(){
            this.tab = 'detalle'
            this.$refs.viewDetalle.otorgarCredito()
        }
    }


}
</script>

<style lang="scss" scoped>
.border-bottom_1{ border-bottom: 3px solid #DBDBDB;color: #DBDBDB}
.active-usd{ color: #03D6BC;}
.active-usd-border{ border-bottom: 3px solid #03D6BC; }
.active-delete{ color: #FF2525;  }
.active-delete-border{ border-bottom: 3px solid #FF2525; }
.active-search{
    background: -webkit-linear-gradient(90deg, #0D4DFF, #03D6BC 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.active-search-border{ border-bottom:3px solid #0D4DFF; }
.active-danger{
    background: -webkit-linear-gradient(90deg, #FF2525, #FF9D32 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.active-danger-border{ border-bottom: 3px solid #FF2525; }

.active-check{ color: #28D07B;}
.active-check-border{ border-bottom: 3px solid #28D07B; }

.inactive{ color: #DBDBDB;border-bottom: 3px solid #DBDBDB !important; }
.br-12{ border-radius: 12px !important; }
.tab-active{ color: #5D5D5D; border-bottom: 2px solid #FF612B !important; }
.tab{color: #5D5D5D; }
a{
    text-decoration:none;
}
</style>
